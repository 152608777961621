import gsap from 'gsap';
import carousel from './modules/carousel';
import matrixImageCarousel from './modules/matrix-image-carousel';
import video from './modules/video';
import slider from './modules/slider-alt';
import reef from './modules/reef';
import nav from './modules/nav';
import home from './modules/home';
import homeMobile from './modules/home-mobile';
import form from './modules/form';
import partners from './modules/partners';
import resources from './modules/resources';
import facts from './modules/facts';
import animations from './modules/animations';
import storiesModel from './modules/stories-modal';
import model from './modules/model';
import map from './modules/map';
import loadMore from './modules/load-more';

home();
homeMobile();
carousel();
matrixImageCarousel();
video();
slider();
reef();
nav();
form();
partners();
resources();
facts();
model();
map();
loadMore();

// if tab key is used, add class to show outline styles
function handleFirstTab(e) {
  if (e.keyCode === 9) {
    document.body.classList.add('user-is-tabbing');
    window.removeEventListener('keydown', handleFirstTab);
  }
}

window.addEventListener('keydown', handleFirstTab);

window.addEventListener('load', function() {
  if (location.pathname === '/') {
    if ('scrollRestoration' in history) {
      // Back off, browser, I got this...
      history.scrollRestoration = 'manual';
      if (window.scrollY) {
        window.scroll(0, 0);  // reset the scroll position to the top left of the document.
      }
    }
  }

  document.body.classList.remove('page-is-loading');
  const loader = document.querySelector('#page-loader');
  gsap.to(loader, {
    opacity: 0,
    display: 'none',
    duration: 0.1,
  })
  animations();
});
