import gsap from 'gsap';
import Glide from '@glidejs/glide';

function setCaptionHeight(carouselEl) {
  const parent = carouselEl.querySelector('.js-slide-captions');
  const captions = Array.from(parent.children);
  const heights = captions.map(caption => caption.offsetHeight);
  const maxHeight = Math.max(...heights);
  gsap.set(parent, {
    height: maxHeight
  });
  console.log(maxHeight);
}

function initCarousel(carouselEl) {
  const carousel = new Glide(carouselEl, {
    type: 'carousel',
    gap: 0,
    dragThreshold: false
  });

  carousel.on(['build.after', 'resize'], function() {
    // Hack: don't know why but setTimeout seems to fix initial height miscalculation
    setTimeout(function () {
      setCaptionHeight(carousel.selector);
    }, 0);
  })

  carousel.on('run.before', function() {
    const caption = carouselEl.querySelector(`[data-caption-index="${carousel.index}"]`);
    gsap.to(caption, {
      opacity: 0,
      y: 10,
      duration: 0.1,
      ease: 'none'
    });
  });

  carousel.on('run.after', function() {
    const slide = carouselEl.querySelector('.glide__slide--active');
    const caption = carouselEl.querySelector(`[data-caption-index="${carousel.index}"]`);
    gsap.fromTo(caption, {
      opacity: 0,
      y: 10,
    }, {
      opacity: 1,
      y: 0,
      duration: 0.1
    });
  });

  carousel.mount();
}

export default function() {
  // TODO: Ideally we should refactor the carousel.js file to allow for multiple image-video carousels on a single page
  const carouselEls = Array.from(document.querySelectorAll('.js-matrix-image-carousel'));
  if (!carouselEls.length) return;
  carouselEls.forEach(el => initCarousel(el));
}
