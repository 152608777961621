function updateGroup(e) {
  const clicked = e.currentTarget;
  const group = clicked.dataset.group;

  if (!group) {
    return;
  }

  const current = Array.from(document.querySelectorAll('.group-is-active'));
  current.forEach(el => el.classList.remove('group-is-active'));

  const groupEl = document.querySelector(`#partner-group-${group}`);
  const groupIntro = document.querySelector(`#partner-group-intro-${group}`);

  clicked.classList.add('group-is-active');
  groupEl.classList.add('group-is-active');
  groupIntro.classList.add('group-is-active');
}

export default function() {
  const btns = Array.from(document.querySelectorAll('.js-partner-filter'));

  if (!btns.length) {
    return;
  }

  btns.forEach(btn => btn.addEventListener('click', updateGroup));
}
