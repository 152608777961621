import gsap from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default function() {
  const reef = document.querySelector('#reef-container');

  if (!reef) {
    return;
  }

  const svg = reef.querySelector('#reef-svg');
  const shapes = Array.from(svg.querySelectorAll('[id^="star"]'));
  const backgroundShapes = Array.from(svg.querySelectorAll('[id^="bg-"]'));
  shapes.reverse();
  backgroundShapes.reverse();

  const tl = gsap.timeline({
    scrollTrigger: {
      trigger: reef,
      start: "top center", // when the top of the trigger hits the center of the viewport
      end: "bottom bottom", // end after scrolling 500px beyond the start
      scrub: true
    }
  });

  tl.add('start')
  .from(shapes, {
    opacity: 0,
    stagger: 1,
    duration: 10,
    ease: 'none'
  }, 'start')
  .from(backgroundShapes, {
    opacity: 0,
    stagger: 10,
    duration: 10,
    ease: 'none'
  }, 'start')
}
