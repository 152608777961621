import gsap from 'gsap';

// This is just for the mobile nav
const navEl = document.querySelector('#mobile-nav');
const btn = document.querySelector('#mobile-nav-btn');
const closeBtn = document.querySelector('#mobile-close-nav-btn');
const body = document.body;
const navLinks = Array.from(navEl.querySelectorAll('.js-nav-link'));

let showTl;
let hideTl;

function initAnims() {
  showTl = gsap.timeline({paused: true})
  showTl
  .set(navEl, {left: 0})
  .fromTo(navEl, {
    xPercent: -100,
  }, {
    xPercent: 0,
    duration: 0.4,
    ease: "power2.out",
  })
  .from(navLinks, {
    y: 20,
    opacity: 0,
    duration: 0.4,
    stagger: 0.05,
  }, '-=0.2')

  hideTl = gsap.timeline({paused: true})
  hideTl
  .to(navEl, {
    xPercent: -100,
    duration: 0.2,
    ease: "none"
  })
  .set(navEl, {left: 'calc(-100% - 100vw)'});
}

function showNav() {
  body.classList.add('nav-is-active')
  showTl.restart()
}

function hideNav() {
  body.classList.remove('nav-is-active')
  hideTl.restart()
}

function toggleNav() {
  if (body.classList.contains('nav-is-active')) {
    hideNav()
  } else {
    showNav()
  }
}

export default function() {
  initAnims();
  btn.addEventListener('click', showNav);
  closeBtn.addEventListener('click', hideNav);
}
