import gsap from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default function() {
  const els = Array.from(document.querySelectorAll('.js-anim-number'));

  if (!els.length) {
    return;
  }

  els.forEach(item => {
    let counter = {
      val: 0
    }

    const factNum = parseInt(item.innerHTML);
    const text = item.innerHTML.split(factNum)[1];

    gsap.to(counter, {
      scrollTrigger: item,
      duration: 5,
      val: factNum,
      onUpdate: function() {
        item.innerHTML = `${Math.ceil(counter.val).toLocaleString()}${text}`;
      },
      ease: "power4.out",
    })
  })
}
