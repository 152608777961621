import gsap from 'gsap';
import Draggable from "gsap/Draggable";
gsap.registerPlugin(Draggable);

let currentVal = 70;
let slider;
let container;
let overlay;
let firstImage;
let picture;

function handleResize() {
  // Setup a timer
  let timeout;

  // Listen for resize events
  window.addEventListener('resize', function() {
  	// If there's a timer, cancel it
  	if (timeout) {
  		window.cancelAnimationFrame(timeout);
  	}

    // Setup the new requestAnimationFrame()
  	timeout = window.requestAnimationFrame(function() {
      // Run our resize functions
      const drag = Draggable.get(overlay);
      const newWidth = container.offsetWidth;
      picture.style.width = `${newWidth}px`;
      firstImage.style.width = `${currentVal}%`;

      drag.applyBounds({
        minX: 0,
        maxX: newWidth,
      });
      gsap.set(overlay, {x: newWidth * currentVal / 100});
      drag.update();
  	});
  }, false);
}

function initSlider() {
  overlay = slider.querySelector('#slider-overlay');
  container = document.querySelector('#slider-container');
  firstImage = slider.querySelector('#slider-image-1');
  picture = firstImage.querySelector('picture');

  const containerWidth = container.offsetWidth;
  const overlayWidth = overlay.offsetWidth;
  const imgEls = Array.from(slider.querySelectorAll('img'));
  imgEls.forEach(item => item.setAttribute('draggable', false));

  picture.style.width = `${containerWidth}px`;
  firstImage.style.width = `${currentVal}%`;

  Draggable.create(overlay, {
    trigger: container,
    type: "x",
    bounds: {
      minX: 0,
      maxX: containerWidth,
    },
    cursor: 'move',
    inertia: false,
    onDrag: function(e) {
      e.stopPropagation();
      e.preventDefault();
      const pos = this.x;
      const currentWidth = container.offsetWidth;
      const percent = Math.round(pos / currentWidth * 100);
      firstImage.style.width = `${percent}%`;
      currentVal = percent;
    },
    onClick: function(e) {
      const pos = e.offsetX;
      const currentWidth = container.offsetWidth;
      const percent = Math.round(pos / currentWidth * 100);
      firstImage.style.width = `${percent}%`;
      gsap.set(overlay, {x: pos});
      this.update();
      currentVal = percent;
    },
  });

  gsap.set(overlay, {x: containerWidth * currentVal / 100});
  Draggable.get(overlay).update();
}

export default function() {
  slider = document.querySelector('#slider');

  if (!slider) {
    return;
  }

  initSlider();
  handleResize();
}
