import gsap from 'gsap';
import Player from '@vimeo/player';

let players = [];
const body = document.body;

function closeModal(params) {
  const { modal, video, player } = params;

  if (player) {
    player.pause();
    player.setCurrentTime(0);
  } else {
    const iframe = video.querySelector('iframe');
    // This is a hack/not ideal as it causes another network request but it's a way around having to use the Youtube iframe embed library (haven't figured out how to use that with es6 modules yet).
    iframe.src = iframe.src;
  }

  const tl = gsap.timeline();
  tl
  .to(modal, {
    opacity: 0,
    duration: 0.1,
  })
  .set(modal, {left: 'calc(-100% - 100vw)'})
  .set(body, {overflow: 'auto'})
}

function showVideo(params) {
  const { modal, video, player } = params;

  const tl = gsap.timeline();
  tl
  .set(modal, {left: 0})
  .set(body, {overflow: 'hidden'})
  .fromTo(modal, {
    opacity: 0,
  }, {
    opacity: 1,
    duration: 0.1,
  })
  .from(video, {
    opacity: 0,
    y: 10,
    duration: 0.1,
    onComplete: () => {
      if (player) {
        player.setCurrentTime(0);
      }
    }
  });
}

function showModal(e) {
  e.preventDefault();
  const clicked = e.currentTarget;
  const url = clicked.getAttribute('href');
  if (!url) {
    return;
  }

  const body = document.body;
  const modal = document.querySelector(`[data-url="${url}"]`);
  if (!modal) {
    return;
  }
  const video = modal.querySelector('.js-resource-video');
  const container = modal.querySelector('.js-resource-video-container');
  const closeBtn = modal.querySelector('.js-resource-video-close');

  let playerObj = players.find(item => item.el === modal);

  if (!playerObj) {
    const modal = document.querySelector(`[data-url="${url}"]`);
    let muted = modal.dataset.sound !== "1" ? true : false;
    const video = modal.querySelector('.js-resource-video');
    const container = modal.querySelector('.js-resource-video-container');
    const closeBtn = modal.querySelector('.js-resource-video-close');
    let player;

    if (url.includes('vimeo')) {
      player = new Player(container, {
        url: url,
        byline: false,
        responsive: true,
        title: false,
        muted: muted,
      });
    } else if (url.includes('youtube')) {
      player = '';
      muted = modal.dataset.sound !== '1' ? '?mute=1' : '';
      let id = url.split('v=')[1];
      // Remove any additional params, e.g. playlist id if client has copy-pasted a youtube playlist url
      id = id.split('&')[0];
      const iframe = `<iframe src="https://www.youtube.com/embed/${id + muted}" title="YouTube video player" frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;
      container.innerHTML = iframe;
    }

    playerObj = {
      el: modal,
      modal: modal,
      player: player,
      video: video,
    }

    players.push(playerObj);
    closeBtn.addEventListener('click', function() {
      closeModal(playerObj);
    });
  }

  showVideo(playerObj);
}

export default function() {
  const links = Array.from(document.querySelectorAll('.js-resource-video-link'));

  if (!links.length) {
    return;
  }

  links.forEach(link => link.addEventListener('click', showModal));
}
