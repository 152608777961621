import gsap from 'gsap';

const mobileStar = document.querySelector('#mobile-star');
const homePromptsParent = document.querySelector('#mobile-home-prompts');
const blocksContainer = document.querySelector('#mobile-overviews');
const overviewBlocks = Array.from(document.querySelectorAll('.js-mobile-overview'));
const images = Array.from(document.querySelectorAll('.js-mobile-overview-image'));
let maxHeight = Math.max.apply(null, overviewBlocks.map(item => item.offsetHeight));

function handleResize() {
  // Setup a timer
  let timeout;

  // Listen for resize events
  window.addEventListener('resize', function() {
  	// If there's a timer, cancel it
  	if (timeout) {
  		window.cancelAnimationFrame(timeout);
  	}

    // Setup the new requestAnimationFrame()
  	timeout = window.requestAnimationFrame(function() {
      // Run our resize functions
      maxHeight = Math.max.apply(null, overviewBlocks.map(item => item.offsetHeight));

      if (blocksContainer.classList.contains('overviews-is-active')) {
        gsap.set(blocksContainer, {height: maxHeight})
      }
  	});
  }, false);
}

function initMobile() {
  const mobileSectionBtns = Array.from(document.querySelectorAll('.js-mobile-section-btn'));
  mobileSectionBtns.forEach(btn => {
    btn.addEventListener('click', showMobileSection);
  });
}

function showMobileSection(e) {
  const index = e.currentTarget.dataset.index;
  const overviewBlock = document.querySelector(`#mobile-overview-${index}`);
  const icon = document.querySelector(`#mobile-section-icon-${index}`);
  const image = images[index];
  const currentIcon = document.querySelector('.mobile-icon-is-active');
  const currentOverview = document.querySelector('.mobile-overview-is-active');
  const currentImage = document.querySelector('.mobile-overview-image-is-active');

  if (currentOverview === overviewBlock) {
    return;
  }

  const tl = gsap.timeline();
  tl.add('start');

  if (currentOverview) {
    currentIcon.classList.remove('mobile-icon-is-active');
    currentOverview.classList.remove('mobile-overview-is-active');
    currentImage.classList.remove('mobile-overview-image-is-active');
    tl.to(currentOverview, {
      opacity: 0,
      zIndex: -1,
      duration: 0.2,
      ease: 'none',
    }, 'start');
  }

  icon.classList.add('mobile-icon-is-active');
  overviewBlock.classList.add('mobile-overview-is-active');
  image.classList.add('mobile-overview-image-is-active');

  if (!blocksContainer.classList.contains('overviews-is-active')) {
    blocksContainer.classList.add('overviews-is-active');
    tl.set(blocksContainer, {height: maxHeight}, 'start')
    .from(blocksContainer, {
      opacity: 0,
      height: 0,
      duration: 0.2,
      immediateRender: false
    }, 'start');
  }

  tl
  tl.to(mobileStar, {
    opacity: 0,
    duration: 0.2,
  }, 'start')
  .to(overviewBlock, {
    opacity: 1,
    duration: 0.2,
    zIndex: 1,
  }, 'start')
  .to(homePromptsParent, {
    opacity: 0,
    height: 0,
    duration: 0.2,
  }, 'start')
  .to(currentIcon, {
    opacity: 0,
    duration: 0.2,
  }, 'start')
  .fromTo(icon, {
    opacity: 0,
  }, {
    opacity: 1,
    duration: 0.2
  }, 'start');
}

export default function() {
  const svg = document.querySelector('#mobile-star');

  if (!svg) {
    return;
  }

  initMobile();
  handleResize();
}
