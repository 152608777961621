import Player from '@vimeo/player';

function initVideo(video) {
  const container = video.querySelector('.js-video-container');
  const url = container.dataset.url;
  let muted;

  if (url.includes('vimeo')) {
    muted = container.dataset.sound !== '1' ? true : false;
    const player = new Player(container, {
      url: url,
      byline: false,
      controls: true,
      responsive: true,
      title: false,
      muted: muted,
    });
  } else if (url.includes('youtube')) {
    muted = container.dataset.sound !== '1' ? '?mute=1' : '';
    const id = url.split('v=')[1];
    const iframe = `<iframe src="https://www.youtube.com/embed/${id + muted}" title="YouTube video player" frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;
    container.innerHTML = iframe;
  }
}

// For non-modal videos we want to play automatically when clicked
function playInlineVideo(video) {
  const container = video.querySelector('.js-video-container');
  const url = container.dataset.url;
  let muted;

  if (url.includes('vimeo')) {
    muted = container.dataset.sound !== '1' ? true : false;
    const player = new Player(container, {
      url: url,
      byline: false,
      controls: true,
      responsive: true,
      title: false,
      muted: muted,
    });
    player.ready().then(function(e) {
      hideImage();
      player.play();
    });
  } else if (url.includes('youtube')) {
    muted = container.dataset.sound !== '1' ? '?mute=1' : '';
    const id = url.split('v=')[1];
    const iframe = `<iframe src="https://www.youtube.com/embed/${id + muted}" title="YouTube video player" frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;
    container.innerHTML = iframe;
  }
}

function hideImage() {
  const image = document.querySelector(".video-full__image");
  image.classList.add('visually-hidden')
}

export default function() {
  const videos = Array.from(document.querySelectorAll('.js-video'));
  if (videos.length === 0) {
    return;
  }

  videos.forEach(video => initVideo(video));

  // This function is for non-modal, non-gallery videos
  document.addEventListener('click', function(e) {
    const clicked = e.target;
    const videoLink = clicked.closest('.js-video-inline');
    if (videoLink) {
      e.preventDefault();
      playInlineVideo(videoLink);
    }
  });
}
