import gsap from 'gsap';
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
let FOV;
if (window.innerWidth >= 1280) {
  FOV = 14;
} else if (window.innerWidth >= 768) {
  FOV = 18;
} else {
  FOV = 25;
}

export default function() {
  const container = document.querySelector('#model-container');

  if (!container) {
    return;
  }

  const scene = new THREE.Scene();
  scene.background = new THREE.Color(0xffffff);
  const camera = new THREE.PerspectiveCamera(FOV, container.offsetWidth / container.offsetHeight, 0.1, 1000);
  camera.position.set(0, 50, 100);
  const renderer = new THREE.WebGLRenderer({
    antialias: true,
  });

  renderer.outputEncoding = THREE.sRGBEncoding;
  renderer.setSize(container.offsetWidth, container.offsetHeight);
  container.appendChild(renderer.domElement);

  const loader = new GLTFLoader();
  const models = [
    '../static/model-plain.gltf',
    '../static/model-sand.gltf',
    '../static/model-coral.gltf',
  ];

  loader.load(models[0], function(obj) {
    let star = obj.scene;
    star.name = `model-active`;
    star.traverse(function(child) {
      if (child.isMesh) {
        gsap.to(child.rotation, {
          y: "+=360",
          duration: 2000,
          ease: 'none',
          repeat: -1,
        })
      }
    });
  	scene.add(star);
    container.classList.remove('model-is-loading');
  }, undefined, function(error) {
  	console.error(error);
  });

  const controls = new OrbitControls(camera, renderer.domElement);
  // to disable zoom
  controls.enableZoom = false;

  function render() {
  	renderer.render(scene, camera);
  }

  function animate() {
  	requestAnimationFrame(animate);
    render()
  }

  function onWindowResize() {
    if (window.innerWidth >= 1280) {
      FOV = 14;
    } else if (window.innerWidth >= 768) {
      FOV = 18;
    } else {
      FOV = 25;
    }
    camera.fov = FOV;
    renderer.setSize(container.offsetWidth, container.offsetHeight);
    camera.aspect = container.offsetWidth / container.offsetHeight;
    camera.updateProjectionMatrix();
  }

  function changeLayer(e) {
    const layer = e.target.id.split('layer-')[1];
    const detail = document.querySelector(`#layer-detail-${layer}`);
    const current = document.querySelector('.layer-is-active');
    const currentModel = scene.getObjectByName('model-active');
    container.classList.add('model-is-loading');
    scene.remove(currentModel);
    loader.load(models[layer], function(obj) {
      let star = obj.scene;
      star.name = `model-active`;
      star.traverse(function(child) {
        if (child.isMesh) {
          gsap.to(child.rotation, {
            y: "+=360",
            duration: 2000,
            ease: 'none',
            repeat: -1,
          })
        }
      });
    	scene.add(star);
      container.classList.remove('model-is-loading');
    }, undefined, function(error) {
    	console.error(error);
    });

    if (current) {
      current.classList.remove('layer-is-active');
    }

    detail.classList.add('layer-is-active');
  }

  function initLayers() {
    const inputs = Array.from(document.querySelectorAll('.js-layer-select'));
    inputs.forEach(input => {
      input.addEventListener('change', changeLayer);
    });
  }

  window.addEventListener('resize', onWindowResize, false);
  animate();
  initLayers();
}
